/*
====> CAROUCEL COMPONENT
*/

.xvia-carousel .slick-prev {
  left: -64px;
}

.xvia-carousel .slick-next {
  right: -64px;
}

@media screen and (max-width: 770px) {
  .xvia-carousel .slick-prev {
    left: 0;
  }

  .xvia-carousel .slick-next {
    right: 0;
  }
}
