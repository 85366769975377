/*
====> SERVICE COMPONENT
*/

.xvia-service-card {
  position: relative;
  box-shadow: 1px 2px 4px 0px rgb(171 171 171 / 10%);
  border: 1px solid #e0e3eb;
  border-radius: 7px;
  background: #fff;
  width: 274px;
  height: 200px;
  cursor: pointer;
  padding: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  transition: transform 100ms ease-in-out;
}

.xvia-service-card---focused {
  transform: scale(1.01);
}

.xvia-service-card__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  max-width: 100%;
  align-self: stretch;
  flex: 1;
}

.xvia-service-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 40px;
  height: 100%;
}

.xvia-service-card__icon {
  text-align: center;
  color: #fdb913;
  max-width: 50px;
  margin-top: 4px;
}
.xvia-service-card__icon svg {
  height: 36px;
  width: auto;
}

.xvia-service-card__rating {
  font-size: 12px;
  color: var(--xvia-color-primary);
}

.xvia-service-card__title-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
}

.xvia-service-card__title {
  font-family: "Montserrat";
  flex: 1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  color: #3d445c;

  text-align: left;
}

.xvia-service-card__sub-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;

  color: #858ead;

  text-align: left;
}

.xvia-service-card__bottom {
  border-top: 1px solid #f1f1f1;
  overflow: hidden;
  align-items: center;
  display: none;
}

.xvia-service-card__bottom-text {
  flex: 1;
  margin-top: 8px;
  margin-bottom: 4px;
}

.xvia-service-card:hover {
  transform: scale(1.01);
}

.xvia-service-card__bottom-icon {
  color: var(--xvia-color-primary);
}

.xvia-service-card__thumb {
  overflow: hidden;
  border-radius: 15px 15px 0 0;
}

.xvia-service-card__thumb img {
  transition: all 0.3s linear 0s;
  max-width: 100%;
}

.xvia-service-card__thumb:hover img {
  transform: scale(1.1);
}

.xvia-service-card__tag {
  border: none;
  color: #777777;
  border-radius: 10px;
  background-color: white;
}

.xvia-service-card__tag.Cidadão {
  color: #093089;
  border: 0.5px solid #093089;
}

.xvia-service-card__tag.Empresa {
  color: #0661a7;
  border: 0.5px solid #0661a7;
}

.xvia-service-card__tag.Servidor {
  color: #02add5;
  border: 0.5px solid #02add5;
}
.xvia-service-card__tag.Gestão-Pública {
  color: #34d5aa;
  border: 0.5px solid #34d5aa;
}

.xvia-service-card__header-right {
  display: flex;
  align-items: center;
  gap: 8px;
}

.xvia-service-card__badge-container {
  position: relative;
}

@keyframes pulse-favorite {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 215, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
  }
}

.xvia-service-card__favorite {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
  font-size: 0.875rem;
  color: transparent;
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  border: none;
  transition: all 0.2s ease-in-out;
  transform-origin: center center;
}

.xvia-service-card__favorite svg {
  color: rgba(0, 0, 50, 0.2);}

.xvia-service-card__favorite:hover {
  color: rgba(0, 0, 0, 0.1);
  transform: scale(1.3);
  cursor: pointer;
}

.xvia-service-card__favorite.is-favorite svg {
  color: #FFD700;
  stroke: none;
}
.xvia-service-card__favorite.is-favorite {
  animation: pulse-favorite 1s ease-out;
  background-color: #fff;
}
