.xvia-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1152px;
}

/* @media (min-width: 768px) {
  .xvia-container {
    width: 750px;
    flex-wrap: wrap;
  }
}

/*
@media (min-width: 992px) {
  .xvia-container {
    width: 1070px;
  }
}

@media (min-width: 1100px) {
  .xvia-container {
    width: 1170px;
  }
} */

@media (max-width: 1216px) {
  .xvia-container {
    padding: 0 24px;
  }
}
