.vehicle-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 100%;
  border-radius: 0.875rem;
  padding: 2.5rem 0rem;
  cursor: pointer;
  gap: 0.875rem;
  text-align: left;
  padding-bottom: 0px;
  margin-bottom: 40px;
}
.wrapp-vehicle-info {
  width: 25%;
}

.vehicle-info {
  margin-bottom: 20px;
}
.vehicle-info h4 {
  color: #000;
}
.vehicle-info p {
  color: #8c8c8c;
}
.wrapp-vehicle-logo {
  width: 25%;
}
.vehicle-logo {
  padding: 15px;
  font-size: 62px;
  text-align: center;
  width: 2em;
  margin-left: 20px;
}

.vehicle-msg {
  width: 100%;
  background-color: var(--VERMELHO_GOV);
  color: #fff;
  border-bottom-left-radius: 0.875rem;
  border-bottom-right-radius: 0.875rem;
  padding: 10px;
  padding-left: 20px;
}
.alert-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.info-container {
  background-color: #034ea2;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.my-vehicle-container {
  display: flex;
  align-items: center;
}

.my-vehicle-container > span {
  margin-left: 5px;
  color: white;
}

.plate-number {
  color: white;
  font-weight: bold;
  margin: 10px 0;
}

.data-container {
  display: flex;
}

.data-card {
  background-color: #a9c8eb;
  border-radius: 4px;
  margin-right: 5px;
  padding: 4px 8px;
}

.data-card > span {
  color: #231f20;
}

@media (max-width: 700px) {
  .wrapp-vehicle-logo {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .wrapp-vehicle-info {
    width: 33%;
  }
  .vehicle-card {
    padding: 0px 16px 0px 16px;
  }
}

@media (max-width: 550px) {
  .wrapp-vehicle-info {
    width: 100%;
  }
  .vehicle-info {
    text-align: center;
  }
}
