.xvia-step__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
}

.xvia-step__title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  /* or 165% */

  color: #000000;
  margin-bottom: 20px;
}

.xvia-step__header h5 {
  margin: 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.xvia-step__content {
  margin-left: 40px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;

  color: #000000;
}

.xvia-step--active .xvia-step__content {
  max-height: 1000px;
}

.xvia-step__number {
  width: 36px;
  min-width: 37px;
  height: 36px;
  background: var(--xvia-color-gray);
  color: var(--xvia-color-text);
  display: inline-block;
  line-height: 37px;
  text-align: center;
  border-radius: 50px;
  margin-right: 16px;
  font-family: var(--xvia-font-family);
  font-size: 16px;
  font-weight: 700;
  transition: background 0.2s ease-in-out;
}

.xvia-step--active .xvia-step__number {
  background: var(--xvia-color-primary);
  color: white;
}
