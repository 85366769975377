.xvia-tag {
  font-family: var(--xvia-font-family);
  font-size: 12px;
  color: #828892;
  text-decoration: none;
  font-weight: 500;
  line-height: 1;
  padding: 12px 14px;
  background: var(--xvia-color-gray);
  display: inline-block;
  margin-right: 11px;
  border-radius: 10px;
  margin-bottom: 16px;
  transition: all 0.2s linear 0s;
}

.xvia-tag:hover {
  background-color: var(--xvia-color-primary);
  color: #fff;
}
