/*====> OMBUDSMAN CARD
*/

.xvia-ombudsman-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 1px 2px 4px 0px rgb(171 171 171 / 10%);
  font-family: var(--xvia-font-family);
  color: var(--xvia-color-text);
  width: 100%;
  height: 134px;
  padding: 8px 16px;
  cursor: pointer;
}

.xvia-ombudsman-card__icon {
  width: 1em;
  height: 1em;
  font-size: 36px;
  color: #034ea2;
  transition: all 0.3s linear;
}

.xvia-ombudsman-card__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #454545;
  margin: 8px 0px 0px 0px;
  text-align: center;
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.xvia-ombudsman-card:hover {
  background: #093089;
  color: #fff;
  border-radius: 5px;
  border-color: #093089;
}

.xvia-ombudsman-card:hover .xvia-ombudsman-card__text {
  color: #fff;
}

.xvia-ombudsman-card:hover .xvia-ombudsman-card__icon {
  color: #fff;
}

@media (max-width: 899px) {
  .xvia-ombudsman-card {
    height: auto;
    flex-direction: row;
    justify-content: start;
  }

  .xvia-ombudsman-card__text {
    margin-top: 0px;
    margin-left: 50px;
  }
}
