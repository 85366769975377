.xvia-service-level-badge {
  color: var(--xvia-color-gray);
}

.xvia-service-level-badge--bronze {
  background-color: var(--xvia-color-bronze);
}

.xvia-service-level-badge--prata {
  background-color: var(--xvia-color-prata);
}

.xvia-service-level-badge--ouro {
  background-color: var(--xvia-color-ouro);
}

.xvia-service-level-badge--opala {
  background-color: var(--xvia-color-opala);
}
