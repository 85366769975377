.xvia-catalog-header--fixed .xvia-catalog-floating-header {
  transform: translateY(0);
}
.xvia-catalog-header--visible
  .xvia-catalog-header--fixed
  .xvia-catalog-floating-header {
  display: none;
  transform: translateY(0);
}
.xvia-catalog-floating-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 32px 0;
  background-color: var(--xvia-color-primary);
  height: 120px;
  z-index: 999;
  transform: translateY(-150px);
  transition: transform 0.2s ease-in-out;
}

.xvia-catalog-floating-header__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.xvia-catalog-floating-header__image-wrapper {
  margin-right: 48px;
}

.xvia-catalog-floating-header__image {
  height: 48px;
}

.xvia-catalog-floating-header__links {
  flex: 1;
}

.xvia-catalog-floating-header__links .xvia-paragraph {
  font-weight: 700;
  font-size: 12px;
  color: white;
  margin-bottom: 0px;
}

.xvia-catalog-floating-header__links ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.xvia-catalog-floating-header__links li > a {
  font-size: 12px;
  font-weight: 500;
  color: white;
}

.xvia-catalog-floating-header__links li + li {
  padding: 0 8px;
  border-left: 1px solid white;
}

.xvia-catalog-floating-header__links li:first-child {
  padding-right: 8px;
}

.xvia-catalog-floating-header__elements {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.xvia-catalog-floating-header .xvia-home-search__input-wrapper {
  width: 500px;
}

@media screen and (max-width: 768px) {
  .xvia-catalog-floating-header__links {
    display: none;
  }

  .xvia-catalog-floating-header {
    display: none;
  }
}
