.xvia-card-section__map {
  border: 0px;
  width: 100%;
  height: 100%;
  padding: 30px 10px;
}

.xvia-card-section {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: #000000;
}
