.xvia-catalog-card {
  border-radius: 7px;
  background: #fff;
  width: 100%;
  padding: 50px;
  margin-top: 30px;
  position: relative;
}

.xvia-catalog-card__title_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;

  color: #000000;
}

.xvia-catalog-card__title_content {
  display: inline-block;
}

.xvia-catalog-card__info_content span {
  display: inline-block;
  padding: 5px;
}

.xvia-catalog-card__subtitle_text{
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

@keyframes pulse-favorite {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 215, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
  }
}


.xvia-catalog-card .xvia-service-card__favorite {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  font-size: 0.875rem;
  color: transparent;
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 3px rgba(0, 0.2, 0, 0.1);
  border: none;
  transition: all 0.2s ease-in-out;
  transform-origin: center center;
  margin-left: 0;
}

.xvia-catalog-card .xvia-service-card__favorite svg {
  color: rgba(0, 0, 50, 0.2);
}

.xvia-catalog-card .xvia-service-card__favorite:hover {
  color: rgba(0, 0, 0, 0.1);
  transform: scale(1.3);
  cursor: pointer;
}

.xvia-catalog-card .xvia-service-card__favorite.is-favorite svg {
  color: #FFD700;
  stroke: none;
}

.xvia-catalog-card .xvia-service-card__favorite.is-favorite {
  animation: pulse-favorite 1s ease-out;
  background-color: #fff;
}

.xvia-catalog-card .MuiSnackbar-root {
  z-index: 10000;
}