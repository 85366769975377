.xvia-link-list__title {
  color: white;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  white-space: nowrap;
}

.xvia-link-list__text {
  color: rgba(255, 255, 255, 0.6);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 35px;
  white-space: nowrap;
}

.xvia-link-list__ul {
  list-style: none;
  color: white;
  padding: 0px;
  margin-top: 20px;
  margin-bottom: 52px;
}

.xvia-link-list__icon {
  margin-right: 10px;
}
