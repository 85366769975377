@import "../../css/colors.css";

.xvia-footer {
  padding: 60px;
  background-color: var(--xvia-color-primary);
}

.rectangle-wrapper {
  display: flex;
  justify-content: space-between;
}

.rectangle-blue,
.rectangle-yellow,
.rectangle-red,
.rectangle-green {
  flex-grow: 1;
  height: 11px;
  flex-shrink: 0;
}

.rectangle-blue {
  background-color: var(--xvia-color-primary);
}

.rectangle-yellow {
  background-color: var(--xvia-color-yellow);
}

.rectangle-red {
  background-color: var(--xvia-color-red);
}

.rectangle-green {
  background-color: var(--xvia-color-green);
}

.xvia-footer__container {
  text-align: left;
}

.xvia-footer__title {
  font-size: 64px;
  color: #181b1d;
  margin-bottom: 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .xvia-footer__title {
    font-size: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .xvia-footer__title {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .xvia-footer__title {
    font-size: 40px;
  }

  .xvia-footer__card {
    text-align: center;
    margin: 0px 0px;
  }

  .xvia-footer {
    padding: 20px;
  }

  .xvia-link-list__text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 35px;
  }
}

.xvia-footer__subtitle {
  color: #777777;
  font-size: 16px;
  display: block;
  font-weight: 700;
  margin-bottom: 0px;
}

.xvia-footer__card {
  display: inline-block;
  margin: 0px 0px;
  text-align: left;
}

.xvia-footer__logo {
  width: 119px;
  height: auto;
  filter: brightness(0) invert(1);
}

.xvia-footer__govpi__logo {
  width: 100px;
  height: auto;
  filter: brightness(0) invert(1);
}

.xvia-footer__govpi__logo__hidden {
  display: none;
}

@media (max-width: 989px) {
  .xvia-footer__govpi__logo {
    display: none;
  }

  .xvia-footer__atipi__logo {
    display: none;
  }
}

.xvia-footer__atipi__logo__hidden {
  display: none;
  filter: brightness(0) invert(1);
}

@media (max-width: 988px) {
  .xvia-footer__govpi__logo__hidden {
    display: block;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100px;
    height: auto;
    margin-bottom: 50px;
  }
  .xvia-footer__atipi__logo__hidden {
    display: block;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100px;
    height: auto;
  }
}

.xvia-footer__service-box {
  box-sizing: border-box;
  display: flex;
}

.xvia-footer__search-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 290px;
  border-radius: 6px;
}

.xvia-footer__atipi__logo {
  width: 190px;
  height: 38px;
  filter: brightness(0) invert(1);
  margin-top: 30px;
}

@media (max-width: 1370px) {
  .xvia-footer__search-bar {
    width: 160px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .xvia-footer__search-bar {
    width: 90%;
    margin-bottom: 25px;
  }
}

.xvia-footer__service-box__hidden,
.xvia-footer__search-bar__hidden {
  display: none;
}

@media (max-width: 767px) {
  .xvia-footer__service-box__hidden,
  .xvia-footer__search-bar__hidden {
    display: block;
  }

  .xvia-footer__service-box__hidden {
    padding: auto;

    box-sizing: border-box;
    text-align: center;

    display: flex;
    margin-top: 30px;
  }

  .xvia-footer__search-bar__hidden {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 35px;
  }

  .xvia-footer__service-box,
  .xvia-footer__search-bar,
  .xvia-footer__atipi__logo {
    display: none;
  }
}

.xvia-line-spacing-footer {
  border: 1px solid rgba(251, 251, 251, 0.4);
  margin-bottom: 67px;
}

.xvia-link-sub-footer-text {
  color: white;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
}
