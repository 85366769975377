/*********
 MAIN BANNER
**********/
.xvia-main-banner {
  min-height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  align-items: flex-end;
  position: relative;
}

.xvia-main-banner__content-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(29, 101, 196, 0.9) 15.95%,
    rgba(217, 217, 217, 0) 100%
  );
  display: flex;
  flex-direction: column;
}

.xvia-main-banner__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.xvia-main-banner__content-wrapper .xvia-home-search {
  height: 218px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.xvia-main-banner__content-wrapper .xvia-home-search .xvia-search-input {
  flex: 1;
  max-width: 800px;
}

.xvia-main-banner__content-wrapper .xvia-home-search .xvia-search-input input,
.xvia-main-banner__content-wrapper .xvia-home-search .xvia-search-input button {
  font-size: 16px;
  height: 72px;
}

.xvia-main-banner__content-wrapper .xvia-home-search .xvia-search-input button {
}

.xvia-main-banner__content-wrapper input:focus {
  outline: none;
}

@media (min-width: 770px) {
}

@media (min-width: 770px) and (max-width: 1200px) {
}
