pre {
  background-color: #1e1e1e;
  color: #d4d4d4;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
  line-height: 1.5;
}

code {
  display: block;
  position: relative;
}

code span {
  counter-increment: line;
  display: block;
  position: relative;
}

code span::before {
  content: attr(data-line);
  position: absolute;
  top: 0;
  left: -30px;
  width: 25px;
  padding-right: 5px;
  color: #808080;
  text-align: right;
  font-size: 0.9em;
  pointer-events: none;
}

.MuiBox_Content {
  display: flex;
  justify-content: space-between;
}

.MuiTypography_Content {
  margin: 0 0 0.5rem 0;
  font-size: 2rem;
  font-weight: 700;
  color: #093089;
}

.MuiTypography_URL {
  display: flex;
  margin-bottom: 0.5rem;
  font-size: 14px;
  color: #777777;
}

.MuiTypography_Description {
  color: black;
  margin-bottom: 0.5rem;
}

.button_mui {
  background-color: #093089;
  color: white;
  border-radius: 5px;
}

.button_mui:hover {
  background-color: #093089;
  opacity: 0.7;
  transition: 0.4s;
}

.MuiAlert_openData {
  margin-bottom: 2rem;
  margin-left: 9.5%;
  max-width: 81%;
}

@media (max-width: 768px) {
  .MuiBox_Content {
    flex-direction: column;
  }
}
