/*********
 MODULES - Category
**********/

.xvia-category-banner {
  margin: 2rem 0;
}

.xvia-category-banner__header {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fdb913;
  border-radius: 14px;
  color: #000000;
  font-size: 2rem;
  gap: 2rem;
  padding: 2rem 3.5rem;
  background-repeat: no-repeat;
  background-position: right center;
  border: none;
  text-align: left;
  cursor: pointer;
}

.xvia-category-banner__header-icon {
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 48px;
  width: 48px;
  height: 48px;
}

.xvia-category-banner__header-title {
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 700;
  margin: 0;
}

.xvia-category-banner__header-title > span {
  opacity: 0.7;
  margin-left: 16px;
}

.xvia-category-banner__header-subtitle {
  font-family: Montserrat;
  font-size: 12px;
  text-decoration: underline;
  margin: 0;
  opacity: 0.7;
  transition: opacity 200ms ease-in-out;
}

.xvia-category-banner__content {
  background-color: #fdb913;
  max-height: 0;
  overflow: hidden;
  transition: max-height 200ms ease-in-out;
  margin-top: -16px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.xvia-category-banner__list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style-type: none;
  margin: 0;
  padding: 2rem 3.5rem;
}

.xvia-category-banner__list-item > button {
  font-family: Montserrat;
  font-weight: 700;
  border: 0;
  border-bottom: 1px solid rgba(38, 38, 38, 0.37);
  text-transform: uppercase;
  padding: 8px 0;
  width: 100%;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
}

.xvia-category-banner__list-item + .xvia-category-banner__list-item {
  border-top: 0;
}

/* OPEN STATE */
.xvia-category-banner--open .xvia-category-banner__header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.xvia-category-banner--open .xvia-category-banner__content {
  max-height: 1000px;
}

.xvia-category-banner--open .xvia-category-banner__header-subtitle {
  opacity: 0;
}

@media (max-width: 960px) {
  .xvia-category-banner__header {
    background-image: none;
    padding: 2rem;
  }

  .xvia-category-banner__header-icon {
    display: none;
  }

  .xvia-category-banner__header-title {
    font-size: 18px;
  }

  .xvia-category-banner__list {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    padding: 2rem;
    padding-top: 0;
  }
}
