@import "../../css/colors.css";

/*********
 MODULES - List
**********/

.xvia-list {
  margin-top: 0px;
}

.xvia-list__head_info {
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.xvia-list__head_content {
  min-height: 79px;
  background-color: var(--xvia-color-primary);
  margin-bottom: 60px;
}

.xvia-list__head_info_container {
  display: flex;
  align-items: center;
  min-height: 79px;
  padding: 1rem;
}

.xvia-list__head_info__title p {
  font-size: 20px;
  margin-top: -25px;
}

.xvia-list__head_info__title {
  font-size: 20px;
  margin-bottom: 0px;
  color: white;
  font-weight: 700;
  font-family: Montserrat;
}

.xvia-list__head_info__title a {
  opacity: 0.6;
  color: inherit;
  font-weight: 400;
}

.xvia-list__head_info__title a:hover {
  opacity: 1;
}

.xvia-new-list__head_content {
  min-height: 79px;
  margin-bottom: 60px;
  padding: 1rem;
  background: rgb(8, 57, 143);
  font-family: "Montserrat";
  background: linear-gradient(90deg,
      rgba(8, 57, 143, 100) 0%,
      rgba(53, 113, 180, 100) 100%);
}

.xvia-new-list__head_info_container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.xvia-new-list__head_info_container h2{
  color: white;
  font-size: 14px;
  font-weight: 300;
}

.xvia-new-list__head_info_container h2 span {
  font-weight: 700;
}

.xvia-new-list__head-title {
  display: flex;
  gap: 2rem;
  align-items: center;
  font-size: 2.5rem;
  color: white;
  font-weight: 700;
}

.xvia-new-list__head-img {
  width: 68px;
  height: 68px;
}

@media (max-width: 768px) {
  .xvia-list__head_info__title {
    font-size: 14px;
  }

  .xvia-list__sidebar-category {
    font-size: 12px !important;
    gap: 1rem !important;
  }

  .xvia-list__sidebar-category-icon {
    margin-left: 0.5rem !important;
  }
}

.xvia-list__title {
  padding: 0;
  border-radius: 6.89px 6.89px 0px 0px;
  margin-bottom: 2rem;
}

.xvia-list__sidebar-filter-input {
  display: flex;
  align-items: center;
  border: solid 1px #d8d8d8;
  font-size: 1rem;
  border-radius: 6.89px;
  height: 58px;
  padding-right: 16px;
}

.xvia-list__sidebar-selected-category {
  display: flex; 
  justify-content: space-between; 
  width: 100%; 
  padding-left: 16px; 
  align-items: center;
}

.xvia-list__sidebar-selected-category-icon {
  color: #dcdcdc;
  font-weight: 700;
  cursor: pointer;
}

.xvia-list__sidebar-input {
  font-family: "Montserrat";
  color: #000;
  padding: 20px 16px;
  height: 55px;
  background-color: #fff;
  border: 0px;
}

.xvia-list__sidebar-input:focus {
  border: 0px;
  outline: none;
}

.xvia-list__sidebar-icon {
  color: #dcdcdc;
  font-weight: 700;
  font-size: medium;
  margin-top: 7px;
  cursor: pointer;
}

.xvia-list__content {
  padding: 16px 20px;
  width: 100%;
  border: solid 2.41px #e0e3eb;
  border-top: 0;
}

.xvia-list__sidebar-category {
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  gap: 2rem;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 400;
  overflow-x: hidden;
  margin-top: 0.5rem;
}

.xvia-list__sidebar-category:hover {
  color: #000;
}

.xvia-list__sidebar-category-icon {
  min-width: 20px;
  min-height: 1px;
  color: #0048b6;
  margin-left: 1rem;
}

.xvia-list__head_info__title-container {
  flex: 1;
}