.xvia-catalog-header {
  padding: 32px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.xvia-catalog-header__image-wrapper {
  flex: 1;
}

.xvia-catalog-header__image {
  height: 64px;
}

.xvia-catalog-header__links ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;
}

.xvia-catalog-header__links li {
  margin-right: 48px;
}

.xvia-catalog-header__links a {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.xvia-catalog-header__elements {
  display: flex;
}

@media (max-width: 768px) {
  .xvia-catalog-header__links {
    display: none;
  }

  .xvia-catalog-header__image {
    height: 48px;
  }
}
