.xvia-info {
  display: inline-block;
}

.xvia-info__icon {
  color: var(--xvia-color-primary);
  margin-right: 4px;
  font-size: 14px;
}

.xvia-info__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;

  color: #000000;
}
