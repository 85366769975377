.xvia-card__borderless-bottom {
  border-bottom: unset;
  padding-bottom: 18px;
}

.xvia-department-section-card {
  padding: 18px;
  padding-bottom: 38px;
  background-color: #ccc;
  max-width: 500px;
  position: relative;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  border-radius: 15px;
  width: 100%;
  text-align: left;
  border: none;
  cursor: pointer;
}

.xvia-department-section-card iframe {
  border: 1px solid #efefef;
  margin-top: 20px;
  width: 100%;
}

.xvia-department__hearder_container {
  height: 120px;
  background-color: var(--xvia-color-primary);
  width: 100%;
  margin-bottom: -100px;
}

.xvia-department__wrapp-info {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  border-bottom: 1px solid #efefef;
}

.xvia-department__company-goals {
  display: flex;
  flex-direction: column;
  width: 55%;
  gap: 2rem;
}

.xvia-department__company-goals .xvia-department__info_section:last-child {
  border: 0px;
}

.xvia-department__info {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 45%;
  border-radius: 16px;
  padding: 39px 35px 39px 35px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.xvia-department__info_section {
  border-bottom: 1px solid #efefef;
  font-family: "Montserrat";
  padding-bottom: 2rem;
}

.xvia-department__info_section h2 {
  color: var(--xvia-color-primary);;
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0 1rem 0;
  line-height: 39px;
}

.xvia-department__info_section {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000;
}

.xvia-department__info-secretary {
  font-family: "Montserrat";
  border-bottom: 1px solid #efefef;
}

.xvia-department__info-secretary:last-child {
  border-bottom: 0px;
}

.xvia-department__info-secretary h3{
  font-size: 1.5rem;
  color: #000;
  line-height: 29px;
}

.xvia-department__info_row {
  margin-bottom: 30px;
}

.xvia-department__section_content {
  margin-top: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.xvia-department__pagination {
  padding-top: 40px;
}

.xvia-catalog__section_list {
  max-height: 200px;
  overflow: auto;
  width: 100%;
  padding: 20px;
}

@media (max-width: 768px) {

  .xvia-department__wrapp-info {
    flex-direction: column;
  }
  
  .xvia-department__company-goals, 
  .xvia-department__info {
    width: 100%;
  }
}
