* {
  font-family: "Montserrat";
}
.xvia-search-input {
  background: #fff;
  padding: 4px 4px;
  border-radius: 7px;
  border: 1px solid #e0e3eb;
}

.xvia-search-input__input {
  width: 100%;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  border: none;
}

.xvia-search-input__input::placeholder {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
}

.xvia-search-input__button {
  background-color: var(--xvia-color-green);
}

@media screen and (max-width: 768px) {
  .xvia-search-input__input  {
    padding-left: 12px;
  }
  .xvia-search-input__input::placeholder  {
    font-size: 14px;
  }
}

