:root {
  --xvia-color-primary: #093089;
  --xvia-color-highlight: #566cd6;
  --xvia-color-gray: #f4f4f4;
  --xvia-color-title: #181b1d;
  --xvia-color-text: #777777;
  --xvia-color-text-secondary: #828892;
  --xvia-color-red: #ef4123;
  --xvia-color-yellow: #fdb913;
  --xvia-color-green: #007236;
  --xvia-font-family: "Montserrat", "DM Sans", sans-serif;
  --xvia-spacing-default: 16px;
  --xvia-spacing-tight: 12px;
  --xvia-spacing-extra-large: 24px;
}
