.xvia-sidebar-card__title {
  padding: 16px 20px;
  border-radius: 6.89px 6.89px 0px 0px;
  color: white;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0px;
}

.xvia-card {
  border: 2px solid #f3f3f3;
  padding: 18px;
  padding-bottom: 38px;
  background-color: white;
}
