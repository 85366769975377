.box-notificacoes {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  overflow: visible;
  z-index: 1000;
  justify-content: center;
}
.box-notificacoes .icone {
  color: #fff;
  font-size: 30px;
}
.box-notificacoes .icone-blue {
  color: #034ea2;
  font-size: 30px;
}
.box-notificacoes .ajuste-borda {
  position: absolute;
  width: 26px;
  height: 26px;
  transform: rotate(45deg);
  top: -14px;
  right: 23px;
  border: 1px solid #d2d7e3;
  border-width: 1px 0px 0px 1px;
  background-color: #fff;
  border-radius: 4px 0 0 0;
}
.box-notificacoes .acoes-panel {
  background-color: #f6f5f5;
  margin-right: 40px;
}
.box-notificacoes .acoes-panel ul li {
  font-size: 0.9rem;
}
.box-notificacoes .container {
  background-color: #fff;
  border: 1px solid #d2d7e3;
  width: 350px;
  min-height: 200px;
  position: absolute;
  top: 60px;
  right: -15px;
  border-radius: 5px;
  padding: 5px 5px 7px 5px;
}
.box-notificacoes .container .header {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3d445c;
}
.box-notificacoes .container .header span {
  display: flex;
  align-items: center;
}
.box-notificacoes .barra-btn {
  padding: 10px 0;
}
.box-notificacoes .barra-btn button {
  margin-left: 10px;
  border-radius: 20px;
}
.box-notificacoes .barra-btn button:first-child {
  margin-left: 20px;
}
.box-notificacoes .list {
  position: relative;
  margin-top: 10px;
  padding: 0px 15px;
  overflow: auto;
  max-height: 350px;
}
.box-notificacoes .list-item {
  align-items: flex-start;
  padding: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #dedddd;
  color: #164194;
  cursor: pointer;
}
.box-notificacoes .list-item:first-child {
  padding-top: 0px;
}
.box-notificacoes .list-item:hover {
  background-color: #f9f9f9;
}
.box-notificacoes .list-item:last-child {
  border: none;
}
.box-notificacoes .icone-msg {
  min-width: auto;
  color: #164194;
}
.box-notificacoes .list-item .icone-msg {
  padding: 3px 5px 0 0;
}
.box-notificacoes .titulo-msg {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  padding-bottom: 3px;
  font-weight: 700;
}
.box-notificacoes .titulo-msg div,
.box-notificacoes .titulo-msg span {
  display: inline-flex;
}
.box-notificacoes .titulo-msg span {
  font-size: 25px;
  line-height: 12px;
}
.box-notificacoes .texto-msg {
  padding: 7px 0;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: #164194;
}
.box-notificacoes .list-item .texto-msg {
  color: #164194;
}
.box-notificacoes .data-msg {
  display: block;
  padding-top: 3px;
  font-weight: 400;
  font-size: 11px;
  color: #555;
}
.box-notificacoes .list .msg-lida .titulo-msg {
  color: #00000099;
}
.box-notificacoes .list .msg-lida .titulo-msg span {
  visibility: hidden;
}
.box-notificacoes .list .msg-lida .texto-msg {
  color: #00000099;
}
.box-notificacoes .list .msg-lida .icone-msg {
  color: #00000099;
}
.box-notificacoes .box-msg {
  padding: 15px;
}
.box-notificacoes .box-msg .header-msg {
  display: flex;
  justify-content: flex-start;
}
.box-notificacoes .box-msg .icone-msg {
  padding-top: 0;
  color: #00000099;
}
.box-notificacoes .box-msg .titulo-msg {
  padding: 5px 0 0 5px;
  color: #00000099;
}
.box-notificacoes .box-msg .texto-msg {
  color: #00000099;
  padding-left: 30px;
}
.box-notificacoes .box-msg .data-msg {
  padding-left: 30px;
}
.box-notificacoes .span-acoes {
  display: inline-block;
  width: 30px;
}
