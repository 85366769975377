.xvia-indicators-section {
  padding: 24px 0;
}

.xvia-indicators-section__container .xvia-section-title {
  margin: 20px 0 32px 0;
}

.xvia-indicators-section__title {
  font-size: 64px;
  color: #181b1d;
  margin-bottom: 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .xvia-indicators-section__title {
    font-size: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .xvia-indicators-section__title {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .xvia-indicators-section__title {
    font-size: 40px;
  }
}

.xvia-indicators-section__subtitle {
  color: #777777;
  font-size: 16px;
  display: block;
  font-weight: 700;
  margin-bottom: 0px;
}
