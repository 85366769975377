.xvia-rating {
  color: var(--xvia-color-primary);
}

.xvia-rating__icon {
  margin-right: 4px;
  transition: opacity 0.2s ease-in-out;
}

.xvia-rating__icon--disabled {
  opacity: 0.1;
}

.xvia-rating__icon--clickable {
  cursor: pointer;
}
