:root {
  --xvia-color-primary: #093089;
  --xvia-color-highlight: #566cd6;
  --xvia-color-gray: #f4f4f4;
  --xvia-color-title: #181b1d;
  --xvia-color-text: #777777;
  --xvia-color-text-secondary: #828892;
  --xvia-color-bronze: #CD7F32;
  --xvia-color-prata: #C0C0C0;
  --xvia-color-ouro: #FFD700;
  --xvia-color-opala: #1D9878;
  --xvia-font-family: "Montserrat", "DM Sans", sans-serif;
  --xvia-spacing-default: 16px;
  --xvia-spacing-tight: 12px;
  --xvia-spacing-extra-large: 24px;
}

*[class^="xvia-"] {
  box-sizing: border-box;
}

div.xvia-content-area {
  padding: 0;
}