.cnh-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--WHITE);
  width: 100%;
  border-radius: 0.875rem;
  padding: 2.5rem 0rem;
  cursor: pointer;
  gap: 0.875rem;
  text-align: left;
  padding-top: 0px;
}
.cnh-wrapp-info {
  width: 100%;
  display: flex;
  gap: 1rem;
  padding-left: 20px;
}
.cnh-wrapp-item-info {
  width: 50%;
}
.cnh-pontos {
  padding: 15px;
  border-right: 1px solid #ccc;
  font-size: 62px;
  text-align: center;
  color: var(--VERDE_GOV);
}
.cnh-pontos h4 {
  font-size: 20px;
}
.cnh-info {
  margin-bottom: 20px;
}
.cnh-info h4 {
  color: var(--VERDE_GOV);
}
.cnh-info p {
  color: var(--SOFT_GREY);
}
.cnh-info-card {
  background-color: #007932;
  width: 100%;
  text-align: left;
  color: #fff;
  border-top-left-radius: 0.875rem;
  border-top-right-radius: 0.875rem;
  margin-bottom: 20px;
  padding: 5px;
  padding-left: 20px;
}
.alert-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.info-container {
  background-color: #034ea2;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.minha-cnh-container {
  display: flex;
  align-items: center;
}

.minha-cnh-container > span {
  margin-left: 5px;
  color: white;
}

.numero-registro {
  color: white;
  font-weight: bold;
  margin: 10px 0;
}

.data-container {
  display: flex;
}

.data-card {
  background-color: #a9c8eb;
  border-radius: 4px;
  margin-right: 5px;
  padding: 4px 8px;
}

.data-card > span {
  color: #231f20;
}

.cnh-info-situacao {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.cnh-info-situacao svg {
  font-size: 1rem;
}

@media (max-width: 550px) {
  .cnh-wrapp-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
  }

  .cnh-wrapp-item-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .cnh-pontos {
    border-bottom: 1px solid #ccc;
    border-right: none;
  }

  .cnh-info-card {
    margin-bottom: 0px;
  }

  .cnh-container {
    gap: 0;
    padding: 0rem;
  }

  .cnh-info {
    text-align: center;
    margin-bottom: 0px;
  }

  .cnh-info-situacao {
    justify-content: center;
  }
}
