.xvia-home-search__input input:focus {
  outline: none;
}

.xvia-home-search__input input {
  height: 45px;
}

@media (min-width: 768px) {
  .xvia-home-search__input {
    margin: auto;
  }
}

.xvia-department-list__wrapp-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0px;
  font-family: "Montserrat";
  cursor: pointer;
}

.xvia-department-list__card {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 2.5rem;
  padding: 2rem 2.5rem;
  border: 2px solid #E0E3EB;
  border-radius: 16px;
  background-color: white;
}

.xvia-department-list__card:hover h2 {
  color: white;
}

.xvia-department-list__card:hover {
  background-color: var(--xvia-color-primary);
}

.xvia-department-list__card h2 {
  font-size: 2rem;
  color: #3D445C;
  margin: 0px;
  font-weight: 700;
  line-height: 39px;
}

.xvia-department-list__card h3 {
  font-size: 1.1rem;
  color: #858EAD;
  margin: 0px;
  font-weight: 400;
  line-height: 21px;
}

.xvia-department-list__card-icon {
  font-size: 60px;
  color: #FDB913;
}