.xvia-header-topbar {
  font-family: 'Montserrat';
  font-style: normal;
  background: linear-gradient(90.11deg, #FDB913 18.79%, #EF4123 105.05%);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding: 16px 16px;

  a,
  a:hover {
    padding: 0 16px;
    border-right: 1px solid #FFF;
    outline: none;
    text-decoration: none;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    color: white;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: 0;
    }
  }
}

.xvia-content-section {
  display: flex;
  flex: 1;
  margin: auto;
}

.xvia-header-section {
  display: flex;
  flex-direction: row;
  font-style: normal;
}

.xvia-header-info {
  display: inline-block;
  font-size: 14px;
  margin-right: 28px;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  span {
    font-weight: 700;
    color: #fff;
  }

  a {
    color: #fff;
  }

  svg {
    max-height: 14px;
  }
}

@media (max-width: 991px) {
  .xvia-header-info {
    display: none;
  }

  .xvia-header-quick-links {
    margin-left: auto;

    a {
      font-size: 12px;
    }
  }
}

.xvia-header-quick-links {
  text-align: right;

  a {
    display: inline-block;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.9);
  }
}