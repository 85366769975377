.xvia-openData-content_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: #000;
  margin-top: 10px;
  margin-bottom: 20px;
}

.xvia-openData-content_Description {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #000;
}

.xvia-openData-list_wrapp-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0px;
  font-family: "Montserrat";
}

.xvia-openData-list_card {
  width: 100%;
  align-items: center;
  border: 1px solid #e0e3eb;
  /* border-radius: 16px; */
  background-color: white;
  transition: background 0.2s;
}

.xvia-MuiAccordionSummary {
  font-weight: 700;
  cursor: pointer;
}

.MuiAccordionSummary-content {
  margin-left: 1rem;
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(90deg);
}

.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
  margin-left: 1rem;
}

.css-1820ip6-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0;
}
