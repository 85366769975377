/*
====> BREAK PAGE
*/

.xvia-section-title {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.xvia-section-title__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;

  color: #000;
  margin-right: 20px;

  text-align: left;
}

.xvia-section-title__link-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-decoration-line: underline;

  margin-top: 5px;

  color: #454545;

  text-align: right;
}

@media (max-width: 480px) {
  .xvia-section-title__link-text {
  }

  .xvia-section-title {
    flex-direction: column;
    display: flex;
    justify-content: start;
    align-items: start;
    margin: 20px 0;
  }

  .xvia-section-title__text {
    line-height: 20px;
  
    color: #000;
    margin-right: 0px;
  
    text-align: left;
  }
}
