.xvia-social-midia__icon {
  color: var(--xvia-color-primary);
}

.xvia-social-midia__content_icon {
  width: 32px;
  height: 32px;
  display: inline-flex;
  filter: brightness(0) invert(1);
  align-items: end;
}

.xvia-social-midia__text {
  color: #ffffff;
}

.xvia-social-midia__content_icon:first-child {
  margin-left: 0px;
}
