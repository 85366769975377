@import "../../../css/colors.css";

.xvia-listing-card {
  position: relative;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.04);
  background: #fff;
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding: 24px 23px 24px 24px;
  min-height: 300px;
  max-width: 500px;
  border: 2.41164px solid #e0e3eb;
  border-radius: 6.89041px;
  display: flex;
  flex-direction: column;
}

.xvia-listing-card__line {
  max-width: 100%;
}

.xvia-listing-card---focused {
  transform: scale(1.01);
}

.xvia-listing-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 40px;
  height: 100%;
}

.xvia-listing-card__header .xvia-listing-card__header-icon {
  text-align: center;
  color: #fdb913;
  max-width: 50px;
  margin-top: 4px;
}

.xvia-listing-card__header .xvia-listing-card__header-icon svg {
  height: 26px;
  width: auto;
}

.xvia-listing-card__content {
  position: relative;
  border-radius: 0 0 15px 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.xvia-listing-card__line .xvia-listing-card__content {
  width: 100%;
}

.xvia-listing-card__content-icon {
  color: var(--xvia-color-yellow);
}

.xvia-listing-card__header-buttons {
  position: absolute;
  right: 30px;
  top: 0;
  transform: translateY(-50%);
  z-index: 3;
}

.xvia-listing-card__rating {
  font-size: 12px;
  color: var(--xvia-color-primary);
  margin-bottom: 10px;
}

.xvia-listing-card__title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: var(--xvia-spacing-tight);
  font-family: Montserrat;
  color: #3d445c;
  font-size: 20px;
  font-weight: 600;
  flex: 1;
  align-items: flex-start;
}

.xvia-listing-card__info {
  margin: 4px 0;
}

.xvia-listing-card__bottom-text {
  flex: 1;
  margin-top: 8px;
  margin-bottom: 4px;
}

.xvia-listing-card__bottom-button {
  margin-top: 1rem;
  box-shadow: none;
  font-weight: 500;
  font-family: Montserrat;
  font-size: 18px;
  text-transform: capitalize;
}

.xvia-listing-card:hover .xvia-listing-card__icon {
  background: #244b9e;
  color: #fff;
}

.xvia-listing-card__bottom-icon {
  color: var(--xvia-color-primary);
}

.xvia-listing-card__subtitle {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  font-family: Montserrat;
  color: #858ead;
  font-size: 16px;
  font-weight: 400;
}

.xvia-listing-card__header-right {
  display: flex;
  align-items: center;
  gap: 8px;
}

.xvia-listing-card__badge-container {
  position: relative;
}

@keyframes pulse-favorite {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 215, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
  }
}

.xvia-listing-card__favorite {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
  font-size: 0.875rem;
  color: transparent;
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  border: none;
  transition: all 0.2s ease-in-out;
  transform-origin: center center;
}


.xvia-listing-card__favorite svg {
  color: rgba(0, 0, 0, 0.1);
}

.xvia-listing-card__favorite:hover {
  color: rgba(0, 0, 0, 0.1);
  transform: scale(1.3);
  cursor: pointer;
}

.xvia-listing-card__favorite.is-favorite svg{
  color: #FFD700;
  stroke: none;
}

.xvia-listing-card__favorite.is-favorite {
  animation: pulse-favorite 1s ease-out;
  background-color: #fff;
}
