.xvia-form-input {
  height: 65px;
  width: 100%;
  background-color: #f4f4f4;
  border: 0;
  padding: 0 30px;
  font-size: 16px;
  color: #a7a7a7;
  border-radius: 16px;
  transition: opacity 0.3s linear 0s;
}

.xvia-form-input--disabled {
  opacity: 0.4;
}
