.xvia-login-area {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.xvia-login-area .xvia-button {
  min-width: 0;
  width: auto;
  white-space: nowrap;
  border-radius: 14px;
  padding: 0 16px;
  color: var(--xvia-color-primary);
}

.xvia-login-area__login-button {
  font-size: 14px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.xvia-login-area .xvia-button:hover {
  color: white;
}

.xvia-login-area__home-button {
  margin-right: 8px;
}

@media (max-width: 960px) {
  .xvia-login-area .xvia-button span {
    display: none;
  }
}
