.xvia-social-network {
  padding: 20px;
  background-color: white;
}

.xvia-social-network__container {
  text-align: center;
}

.xvia-social-network__title {
  font-size: 64px;
  color: #181b1d;
  margin-bottom: 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .xvia-social-network__title {
    font-size: 60px;
  }

  .xvia-social-network {
    padding: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .xvia-social-network__title {
    font-size: 50px;
  }

  .xvia-social-network {
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .xvia-social-network__title {
    font-size: 40px;
  }

  .xvia-social-network {
    padding: 20px;
  }
}

.xvia-social-network__subtitle {
  color: #777777;
  font-size: 16px;
  display: block;
  font-weight: 700;
  margin-bottom: 0px;
}

.xvia-social-network__card {
  display: flex;
  text-align: center;
}
