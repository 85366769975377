/*====> SOCIAL NETWORK CARD
*/

.xvia-social-network-card {
  align-items: center;

  text-align: left;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  /* box-shadow: 0px 16px 32px 0px rgb(171 171 171 / 10%); */
  font-family: var(--xvia-font-family);
  color: var(--xvia-color-text);
  width: 100%;
  max-width: 306px;
  height: 51px;
  display: flex;
  /* margin: 5px; */
  cursor: pointer;
}

.xvia-social-network-card__icon {
  width: 35px;
  height: 35px;
  line-height: 0;
  font-size: 10px;
  font-weight: 200;
  color: #c1c1c1;
  margin-right: 8px;
  margin-left: 8px;
  transition: all 0.3s linear;
}

.xvia-social-network-card__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 14px;
  color: #313131;
  margin-top: 8px;
  margin-left: 8px;

  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.xvia-social-network-card:hover {
  background: #093089;
  color: #fff;
  border-radius: 5px;
  border-color: #093089;
}

.xvia-social-network-card:hover .xvia-social-network-card__text {
  color: #fff;
}

.xvia-social-network-card:hover .xvia-social-network-card__icon {
  color: #fff;
}
