.xvia-button {
  font-family: var(--xvia-font-family);
  display: inline-block;
  color: #fff;
  border-radius: 7px;
  font-weight: 500;
  font-size: 14px;
  border: 0;
  cursor: pointer;
  min-width: 120px;
  transition: background 0.2s ease-in-out;
  width: 100%;
}

.xvia-button---primary {
  background: var(--xvia-color-primary);
  padding: 0 10px;
  height: 50px;
  line-height: 22px;
}

.xvia-button---secondary {
  color: var(--xvia-color-text-secondary);
  padding: 0 10px;
  height: 50px;
  line-height: 22px;
  font-weight: 500;
}

.xvia-button---circle {
  border-radius: 100%;
  height: 37px;
  width: 37px;
  min-width: 37px;
  padding: 0px 10px;
  line-height: 0px;
}

.xvia-button---secondary:hover {
  background: var(--xvia-color-primary);
  color: white;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .xvia-button {
    min-width: 60px;
  }
}
