.xvia-catalog__card_information {
  border-radius: 7px;
  border: 0px none #f3f3f3;
  width: 100%;
  margin-top: 15px;
}

.xvia-catalog__hearder_container {
  height: 80px;
  background-color: var(--xvia-color-primary);
  width: 100%;
}

.xvia-list_grid {
  margin-top: 30px;
}

.xvia-catalog__info_section {
  padding: 30px;
}

.xvia-catalog__card_information_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  /* or 165% */

  color: #000000;
  margin-bottom: 20px;
}

.xvia-catalog__card_information_sub_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;

  color: #000000;
}

.xvia-catalog__card_information_sub_title_card {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  color: #000000;
}

.xvia-catalog__info_section h2 {
  padding-bottom: 15px;
}

.xvia-catalog__info_row {
  margin-bottom: 30px;
}

.xvia-catalog__section_list {
  max-height: 200px;
  overflow: auto;
  width: 100%;
  border: 1px solid #d8d8d8;
  border-radius: 14px;
}

.xvia-catalog__info_row_cannel {
  margin-left: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;

  color: var(--xvia-color-title);
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  padding-left: 10px;
  padding: 10px;
}
.xvia-catalog__info_row_cannel span {
  font-size: 14px;
  color: var(--xvia-color-text);
}

.xvia-home-search__gradient {
  width: 100%;
  height: 15px;
  background: rgb(8, 57, 143);
  background: linear-gradient(
    90deg,
    rgba(8, 57, 143, 1) 0%,
    rgba(6, 96, 167, 1) 17%,
    rgba(3, 173, 213, 1) 38%,
    rgba(51, 213, 170, 1) 58%,
    rgba(139, 235, 105, 1) 80%,
    rgba(185, 251, 17, 1) 100%
  );
}

.xvia-catalog__container {
  padding-bottom: 30px;
}
